import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const siteContext = React.createContext();

const Provider = function Provider({ children }) {
  const [isHireTalent, setIsHireTalent] = useState(true);

  const handleChange = (bool) => {
    setIsHireTalent(bool);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <siteContext.Provider value={{
      isHireTalent,
      handleChange,
    }}
    >
      {children}
    </siteContext.Provider>
  );
};

// eslint-disable-next-line react/prop-types,react/function-component-definition
export default ({ element }) => <Provider>{element}</Provider>;

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};
